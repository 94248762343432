<template>
  <ion-page>
    <ion-content fullscreen>    
      <template v-if="step == 1">
        <h3>{{$t('labelOtherItemTitle')}}</h3>

        <ion-card v-for="(data, cardIndex) in mobileListCheck" :key="data"> 
          <ion-card-header>
            <ion-card-title>{{data}}</ion-card-title>
          </ion-card-header>        
          <ion-card-content>
            <ion-list class="Survey" lines="none">
                <ion-radio-group :value="mobileCheck[cardIndex]" @ionChange="handleMobileCheck($event, cardIndex)">
                  <template v-if="cardIndex < 3">                  
                  <ion-item v-for="(item, index) in mobileListRadioType1" :key="index">
                    <ion-label>{{ item.labal }}</ion-label>
                    <ion-radio slot="start" :value="item.value"></ion-radio>
                  </ion-item>
                  </template>
                  <template v-if="cardIndex >= 3">
                    <ion-item v-for="(item, index) in mobileListRadioType2" :key="index">
                      <ion-label>{{ item.labal }} </ion-label>
                      <ion-radio slot="start" :value="item.value"></ion-radio>
                    </ion-item>                  
                  </template>
                </ion-radio-group>
            </ion-list>
          </ion-card-content>
          
        </ion-card>
      </template>

      <template v-if="step == 2">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{$t('labelLcdQuestionTitle')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            
            <ion-list lines="none">
              <ion-radio-group :value="lcdCheck" @ionChange="handleLcdCheck">
                  <ion-item v-for="(item, index) in mobileListRadioType3" :key="index">
                    <ion-label>{{ item.labal }} </ion-label>
                    <ion-radio slot="start" :value="item.value"></ion-radio>
                  </ion-item>
                </ion-radio-group>
            </ion-list>

            <ion-list class="Survey" lines="none">
              <ion-grid>
                <ion-row>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/lcd/img_lcd1.png" alt="Example 1" class=""></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 1
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/lcd/img_lcd2.png" alt="Example 2"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 2
                    </ion-label>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/lcd/img_lcd3.png" alt="Example 3"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 3
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/lcd/img_lcd4.png" alt="Example 4"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 4
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/lcd/img_lcd5.png" alt="Example 5"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 5
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/lcd/img_lcd6.png" alt="Example 6"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 6
                    </ion-label>    
                  </ion-col>
                </ion-row>

              </ion-grid>
            </ion-list>

          </ion-card-content>
        </ion-card>
      </template>  


      <template v-if="step == 3">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{$t('labelExteriorQuestionTitle')}}</ion-card-title>
          </ion-card-header>
          <ion-card-content>

            <ion-list lines="none">
              <ion-radio-group :value="surfaceCheck" @ionChange="handleSurfaceCheck">
                  <ion-item v-for="(item, index) in mobileListRadioType3" :key="index">
                    <ion-label>{{ item.labal }} </ion-label>
                    <ion-radio slot="start" :value="item.value"></ion-radio>
                  </ion-item>
                </ion-radio-group>
            </ion-list>

            <ion-list class="Survey" lines="none">
              <ion-grid>
                <ion-row>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/surface/img_surface1.png" alt="Example 1"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 1
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/surface/img_surface2.png" alt="Example 2"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 2
                    </ion-label>
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/surface/img_surface3.png" alt="Example 3"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 3
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/surface/img_surface4.png" alt="Example 4"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 4
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/surface/img_surface5.png" alt="Example 5"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 5
                    </ion-label>    
                  </ion-col>
                  <ion-col size="6">
                    <ion-label>
                    <ion-img src="/assets/images/mobile/surface/img_surface6.png" alt="Example 6"></ion-img>
                    {{$t('labelAnswerAbnormalExample')}} 6
                    </ion-label>    
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-list>

          </ion-card-content>
        </ion-card>
      </template>


      <template v-if="step == 4">

        <ion-list class="Survey" lines="none">
          <span class="title">{{ $t('labelPictureWarning') }}</span>
          <ion-grid>
            <ion-row size="12">
                <template v-for="index in  10" :key="index">

                  <ion-col size="6">
                   <ion-label>  
                      <template v-if="photo[index-1] == undefined">
                        <ion-img src="/assets/images/common/no-image.png" @click="handlePhoto(index-1)"/>
                      </template>
                      <template v-else> 
                        <ion-img
                          v-if="photo[index-1]"
                          :src="photo[index-1]"
                          @ionError="photo[index-1] = '/assets/images/common/no-image.png'"
                          @click="handlePhoto(index-1)"
                          />                            
                      </template>
                      <ion-button v-if="photo[index-1] === undefined" @click="handlePhoto(index-1)" color="tertiary" expand="full">
                        <span>{{$t(`labelPpicSubject${index}`)}} </span>
                        <ion-icon :icon="cameraOutline"></ion-icon>
                      </ion-button>
                      <ion-button v-if="photo[index-1]" class="clear" @click="removePhoto(index-1)" color="tertiary" expand="full">
                        <ion-icon :icon="trash"></ion-icon>
                      </ion-button>                   
                    </ion-label>  
                  </ion-col>
                </template>
            </ion-row>      


          </ion-grid>
        </ion-list>
      </template>

      <ion-button color="tertiary" size="" expand="full" @click="handleNext">{{$t('buttonLabelNext')}}</ion-button>
      <ion-loading :is-open="isOpenLoading" message="Loading..." spinner="circles"></ion-loading>
    </ion-content>
  </ion-page>
</template>

<script>
import Cookies from 'js-cookie'
import { 
  IonPage,
  IonContent, 
  IonButton, 
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  useIonRouter
} from '@ionic/vue'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { cameraOutline, trash } from 'ionicons/icons'
import { Camera, CameraResultType } from '@capacitor/camera'
import { DataURIToBlobNew } from '@/utils/commonUtils'
import { addFileData } from '@/api/board/common_v2'
import { useI18n } from 'vue-i18n'

export default {
  name: 'survey',
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonLabel,
    IonRadio,
    IonGrid,
    IonRow,
    IonCol,
    IonLoading
  },
  setup() {
    const { t } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const router = useIonRouter()

    //선언
    const mobileCheckMaxCount = 7  
    let step = ref(1)
    let mobileCheck = ref([]);
    let lcdCheck = ref(undefined)
    let surfaceCheck = ref(undefined)  
    let isOpenLoading = ref(false)

    //첨부파일
    let photo = ref([])

    /*
    //테스트로 미리 데이터 셋팅
    for (let i = 0; i <= 9; i++) {
      photo.value[i] = undefined
      if(i < 7){
        mobileCheck.value[i] = 'N'
      }
    }

    lcdCheck.value = 'N'
    surfaceCheck.value = 'N'
    */

    //기본 데이터 셋팅
    let mobileListCheck = [
      t('labelOtherItem1Title'),
      t('labelOtherItem2Title'),
      t('labelOtherItem3Title'),
      t('labelOtherItem4Title'),
      t('labelOtherItem5Title'),
      t('labelOtherItem6Title'),
      t('labelOtherItem7Title'),
    ]

    const mobileListRadioType1 = [
      {labal : t('otherItemAnser1'), value : 'Y'},
      {labal : t('otherItemAnser2'), value : 'N'},
    ]

    const mobileListRadioType2 = [
      {labal : t('otherItemAnser3'), value : 'Y'},
      {labal : t('otherItemAnser4'), value : 'N'},
      {labal : t('otherItemAnser5'), value : 'A'},
    ]

    const mobileListRadioType3 = [
      {labal : t('answerNormal'), value : 'Y'},
      {labal : t('answerLittleAbnormal'), value : 'N'},
      {labal : t('answerStrongAbnormal'), value : 'A'},
    ]


    onMounted(async () => { 
      
    })

    watch(
      () => route.params,
      async () => {
        if(route.path.indexOf('/mobile/survey') > -1 ){
          step.value = 1
          lcdCheck.value = undefined
          surfaceCheck.value = undefined
          mobileCheck.value = []
          photo.value = []
          isOpenLoading.value = false

          for (let i = 0; i < 7; i++) {
            mobileCheck.value[i] = i < 3  ? 'N' : 'A'
          }          
        }        
      },
      {
        immediate: true
      }
    )

    const dataUpdate = async() => {

      const apiUrl = 'api/atm/hardwareinspection/update'

      //파일 정보 처리
      let formData = new FormData()
      photo.value.forEach((e, k) => {
          if(e !== undefined){
            const index = k + 1            
            const file = DataURIToBlobNew(e)          
            const fileOfBlob = new File([file], `file${index}.jpg`, {type:'image/jpeg'})        
            formData.append('file_'+index, fileOfBlob)
          }
      })

      //기본정보
      const memId = Cookies.get('memId')
      formData.append('ord_number', route.params.num)
      formData.append('mem_id', memId)
      formData.append('pen', mobileCheck.value[0])
      formData.append('usimtray', mobileCheck.value[1])
      formData.append('backcover', mobileCheck.value[2])
      formData.append('touchid', mobileCheck.value[3])
      formData.append('faceid', mobileCheck.value[4])
      formData.append('iris', mobileCheck.value[5])
      formData.append('usimcheck', mobileCheck.value[6])
      formData.append('picture_front_check', lcdCheck.value)
      formData.append('picture_back_check', surfaceCheck.value)
      formData.append('fingerprint', '')
      formData.append('ord_type', 'Y')
      
  
      isOpenLoading.value = true
      await addFileData(apiUrl, formData).then((response) => {

        //토글로 오류를 표기하여 따로 제어하진 않음
        isOpenLoading.value = false
        
        //정상으로 넘어오면 처리 완료 (결과 값이 따로 없음)
        if(response.status == 200){
            Cookies.set(route.params.num, '1', { expires: 2 })
            router.push({
              name: 'mobile-checkup',          
              params: {
              }
          })
        }
      })
    }
  
    const mobileCheckValidation = () => {
      //상세 체크는 반복문 돌려서 값 체크 후 진행 (현재는 클릭 데이터가 존재하면 넘어감) 
      return  mobileCheck.value.length != mobileCheckMaxCount ? false : true
    }

    const handleNext = () => {

      let result = true

      //기본
      if(step.value == 1){        
        if(!mobileCheckValidation()){
          result = false
          alert(t('orderRequireInfo'))
        }
      }else if(step.value == 2){
        if(lcdCheck.value === undefined || lcdCheck.value === ''){
          result = false
          alert(t('orderRequireInfo'))
        }
      }else if(step.value == 3){
        if(surfaceCheck.value === undefined || surfaceCheck.value === ''){
          result = false          
          alert(t('orderRequireInfo'))
        }
      }

      if(!result){
          return false
      }else{
        if(step.value == 4){
          dataUpdate()
        }else if(result && step.value < 4){
          step.value++
        }
      }
            
    }

    const handleMobileCheck = (e, index) => {  
      mobileCheck.value[index] = e.detail.value    
    }

    const handleLcdCheck = (e) => {  
      lcdCheck.value = e.detail.value    
    }

    const handleSurfaceCheck = (e) => {  
      surfaceCheck.value = e.detail.value    
    }
    

    //첨부파일 처리
    const handlePhoto = async (index) => {
      const image = await Camera.getPhoto({
        quality: 10,
        allowEditing: true,
        resultType: CameraResultType.DataUrl
      })

      photo.value[index] = image.dataUrl
    }

    const removePhoto = async (index) => {
      photo.value[index] = undefined
    }

    return {
      isOpenLoading,
      cameraOutline, 
      trash,
      handlePhoto,
      removePhoto,
      photo,
      mobileListCheck,
      mobileListRadioType1,
      mobileListRadioType2,
      mobileListRadioType3,
      step,
      mobileCheck,
      lcdCheck,
      surfaceCheck,
      handleNext,
      handleMobileCheck,
      handleLcdCheck,
      handleSurfaceCheck,
      dataUpdate,
    }
  }
}
</script>

<style>
h3 {
  margin-bottom: 20px;
  font-size: 16px;
}

ion-list .title {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
}

ion-card {
  margin-inline: 0;
}

ion-card-title {
  font-size: 16px;
}

ion-radio {
  margin-inline: 0;
  margin-inline-end: 15px;
}

ion-item {
  --padding-start: 0;
}

ion-tab-bar > ion-tab-button > ion-label {
  font-size:  14px;
}
</style>